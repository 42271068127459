import React from "react";
import BannerSection from "./sections/BannerSection";
import BrandsSection from "./sections/BrandsSection";
import CategoriesSection from "./sections/CategoriesSection";
import NewArrivalsSection from "./sections/NewArrivalsSection";
import { createEntryPage } from "../FullPageLayout/FullPageLayout";
import Box from "../../elements/v2/Box/Box";
// import BestDealsSection from "./sections/BestDeals";

type Props = {
  new_arrivals_collection_id?: number;
};

const DiscoveryPage = ({ new_arrivals_collection_id }: Props) => (
  <Box mb={8}>
    <BannerSection />
    {/* <BestDealsSection /> */}
    <BrandsSection />
    {new_arrivals_collection_id && <NewArrivalsSection collectionId={new_arrivals_collection_id} />}
    {/*<DesignerEditsSection />
    todo: remove DesignerEditsSection component if we won't ever use it again
    */}
    <CategoriesSection />
  </Box>
);

type Args = {
  topbar: any; // This will remain a black box to typescript until we rewrite it
  discoveryPage: Props;
};

const mapEntryProps = ({ topbar, discoveryPage }: Args) => ({
  pageProps: {
    topbarProps: topbar,
  },
  componentProps: discoveryPage,
});

export default createEntryPage(mapEntryProps)(DiscoveryPage);
