import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import axios from "axios";
import { AuthenticityToken, getCsrfHeaders } from "../../../../utils/csrfHelper";
import styled from "styled-components";
import Button from "../../../elements/v2/Button/Button";
import PriceBreakdown, { OrderSummaryProps } from "./PriceBreakdown/PriceBreakdown";
import Typography from "../../../elements/v2/Typography/Typography";
import { Form, Formik, FormikProps } from "formik";
import Box from "../../../elements/v2/Box/Box";
import { FormValues, UserProps } from "../CheckoutPage";
import SectionDivide from "../elements/SectionDivide";
import { Section } from "../elements/Section";
import Header from "../elements/Header";
import StripeAddress from "./StripeAddress";
import SnackbarAlert from "../../../composites/v2/SnackbarAlert/SnackbarAlert";
import {
  DefaultValuesOption,
  PaymentMethodResult,
  StripeAddressElementOptions,
} from "@stripe/stripe-js";
import LoadingIndicator from "../../../elements/LoadingIndicator/LoadingIndicator";
import {
  handleNextStripeAction,
  PaymentIntentPartial,
  processConfirmIntent,
  processFailedIntent,
} from "../lib/stripe";

export interface StripeFormProps {
  shippingRequired: boolean;
  googleMapsApiKey: string;
  emailAddress: string;
  formAction: string;
}

interface AdditionalFormProps {
  deliveryMethod: string;
  authorName: string;
  currentUser: UserProps;
  orderSummaryProps: OrderSummaryProps;
}

export type FormProps = StripeFormProps & AdditionalFormProps;

interface FormResponse {
  redirectUrl?: string | undefined;
  stripePaymentIntent?: PaymentIntentPartial | undefined;
  errorMsg?: string | undefined;
}

const SHIPPING_ADDRESS_VALUES = {
  country: "Australia",
  name: "",
  street1: "",
  street2: "",
  city: "",
  state_or_province: "",
  postal_code: "",
};

const FORMIK_INITIAL_VALUES = {
  shipping_address: SHIPPING_ADDRESS_VALUES,
  payment_type: "stripe",
};

export default function CheckoutForm({
  shippingRequired,
  googleMapsApiKey,
  emailAddress,
  formAction,
  deliveryMethod,
  authorName,
  orderSummaryProps,
  currentUser,
}: FormProps) {
  const {
    discountCodeDetails,
    priceBreakDownProps: { rental, offer },
  } = orderSummaryProps;

  const stripeObject = useStripe();
  const stripeElements = useElements();

  const stripeGenericError =
    "An error occurred during the payment process. Could not finalize your Stripe payment.";
  const [isBillingSameAsShipping, setIsBillingSameAsShipping] = useState(true);
  const [billingError, setBillingError] = useState(false);
  const [alertMessage, setAlertMessage] = useState(stripeGenericError);
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const mounted = useRef(false);
  const handleOnChangeRef = useRef(null);

  const defaultBillingValues = useMemo(() => {
    let values: DefaultValuesOption["billingDetails"] = {
      name: currentUser.name,
    };
    if (currentUser.address)
      values = {
        ...values,
        address: {
          line1: currentUser.address.street1 || "",
          country: currentUser.address?.country,
          line2: currentUser.address.street2 || "",
          city: currentUser.address.city || "",
          state: currentUser.address.state_or_province,
          postal_code: currentUser.address.postal_code || "",
        },
      };
    return values;
  }, []);

  const defaultShippingValues = useCallback(({ shipping_address }: FormValues) => {
    return {
      name: shipping_address.name || currentUser.name,
      address: {
        line1: shipping_address.street1 || currentUser?.address.street1 || "",
        country: shipping_address.country || currentUser?.address?.country,
        line2: shipping_address.street2 || currentUser?.address.street2 || "",
        city: shipping_address.city || currentUser?.address.city || "",
        state: shipping_address.state_or_province || currentUser?.address.state_or_province,
        postal_code: shipping_address.postal_code || currentUser?.address.postal_code || "",
      },
    };
  }, []);

  const [billingAddress, setBillingAddress] = useState<
    DefaultValuesOption["billingDetails"] | StripeAddressElementOptions["defaultValues"]
  >(defaultBillingValues);

  const { priceCents, currencyCode } = offer;
  const { quantity, startOn, endOn } = rental;

  const initialValues = {
    ...FORMIK_INITIAL_VALUES,
    start_on: startOn || "",
    end_on: endOn || "",
    offer_price: `${priceCents || ""}`,
    offer_currency_code: currencyCode || "",
    quantity: `${quantity || ""}`,
    delivery: deliveryMethod || "",
    discount_code: discountCodeDetails.discountCode,
  };

  const isBillingAddressInvalid = useMemo(() => {
    if (billingAddress.name === "") return true;

    const { address } = billingAddress;
    let isInvalid = false;

    Object.entries(address).forEach((field) => {
      if (field[0] !== "line2" && field[1] === "") isInvalid = true;
    });

    return isInvalid;
  }, [billingAddress]);

  // Instead of passing billing address this way to stripe, we could have used the stripe address element
  // for billing and our own  (new component) for shipping. It could have been more straightforward.
  const resetBillingAddress = (billingAddress: any) => {
    let addressElement = stripeElements.getElement("address");
    addressElement.unmount();
    addressElement.destroy();
    addressElement = stripeElements.create("address", {
      mode: "billing",
      defaultValues: billingAddress as StripeAddressElementOptions["defaultValues"],
      autocomplete: {
        mode: "google_maps_api",
        apiKey: googleMapsApiKey,
      },
    });
    addressElement.mount(document.getElementById("address-element"));
    addressElement.on("change", handleOnChangeRef.current);
  };

  const handleOnAlertDismiss = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setIsAlertOpen(false);
  };

  const startLoading = useCallback(() => mounted.current && setLoading(true), [loading]);

  const handleError = useCallback(
    (message) => {
      if (mounted.current) {
        setLoading(false);
        setAlertMessage(message);
        setIsAlertOpen(true);
      }
    },
    [loading, alertMessage, isAlertOpen]
  );

  const handleFormResponse = useCallback(
    async (data: FormResponse, formValues: FormValues) => {
      if (!mounted.current) return;

      const { redirectUrl, stripePaymentIntent, errorMsg } = data;

      if (redirectUrl) {
        window.location.href = redirectUrl;
        return;
      }

      if (errorMsg) {
        handleError(errorMsg);
        resetBillingAddress(defaultShippingValues(formValues));
        return;
      }

      if (!stripePaymentIntent) return;

      const {
        requiresAction,
        clientSecret,
        stripePaymentId,
        failedIntentPath,
        confirmIntentPath,
      } = stripePaymentIntent;

      if (!requiresAction) return;

      const result = await handleNextStripeAction(stripeObject, clientSecret);

      if (!mounted.current) return;

      if (result.error) {
        const response = await processFailedIntent(stripePaymentId, failedIntentPath);

        if (!mounted.current) return;
        window.location.href = response.data.redirectUrl;
        return;
      }

      // The card action has been handled
      // The PaymentIntent can be confirmed again on the server
      const response = await processConfirmIntent(
        stripePaymentId,
        result.paymentIntent.id,
        confirmIntentPath
      );

      if (!mounted.current) return;

      if (response.data.success) {
        window.location.href = response.data.redirectUrl;
        return;
      }

      handleError(response.data.errorMsg);
      resetBillingAddress(defaultShippingValues(formValues));
    },
    [stripeObject, handleError, mounted.current]
  );

  const formSubmit = async (
    formValues: FormValues,
    paymentMethodId: string,
    paymentMethodType: string
  ) => {
    const formData = new FormData();
    if (discountCodeDetails.isDiscountCodeSet) {
      formData.append("discount_code", discountCodeDetails.discountCode);
    }
    if (formValues?.offer_price?.length !== 0) {
      formData.append("offer_price", formValues.offer_price);
      formData.append("offer_currency_code", formValues.offer_currency_code);
    }
    if (formValues?.quantity?.length !== 0) {
      formData.append("quantity", formValues.quantity);
    }
    if (shippingRequired) {
      formData.append("shipping_address[name]", formValues.shipping_address.name);
      formData.append("shipping_address[country]", formValues.shipping_address.country);
      formData.append("shipping_address[street1]", formValues.shipping_address.street1);
      formData.append("shipping_address[street2]", formValues.shipping_address.street2 || "");
      formData.append("shipping_address[city]", formValues.shipping_address.city);
      formData.append(
        "shipping_address[state_or_province]",
        formValues.shipping_address.state_or_province
      );
      formData.append("shipping_address[postal_code]", formValues.shipping_address.postal_code);
    }
    formData.append("start_on", formValues.start_on);
    formData.append("end_on", formValues.end_on);
    formData.append("delivery", formValues.delivery);
    formData.append("payment_type", formValues.payment_type);
    formData.append("stripe_payment_method_id", paymentMethodId);
    formData.append("payment_method_type", paymentMethodType);

    const result: { data: FormResponse } = await axios.post(formAction, formData, {
      withCredentials: true,
      headers: {
        ...getCsrfHeaders(),
        "Content-Type": "multipart/form-data",
      },
    });

    await handleFormResponse(result.data, formValues);
  };

  const handleSubmit = async (formikValues: FormValues) => {
    if (!stripeObject) return;

    startLoading();

    if (!isBillingSameAsShipping) {
      if (isBillingAddressInvalid) {
        handleError("Please fill all billing details");
        return;
      }
      resetBillingAddress(billingAddress);
    }

    const { error: submitError } = await stripeElements.submit();

    if (!mounted.current) return;

    if (submitError) {
      handleError(submitError.message);
      resetBillingAddress(defaultShippingValues(formikValues));
      return;
    }

    const result: PaymentMethodResult = await stripeObject.createPaymentMethod({
      elements: stripeElements,
    });
    let paymentMethodType = result.paymentMethod.type;
    // Google and Apple Pay are classed as a wallet
    const doesUseWallet = paymentMethodType === "card" && result.paymentMethod.card.wallet;
    if (doesUseWallet) {
      paymentMethodType = result.paymentMethod.card.wallet.type;
    }

    if (!mounted.current) return;

    if (result.error) {
      handleError(result.error.message);
      resetBillingAddress(defaultShippingValues(formikValues));
      return;
    }

    // Otherwise send paymentMethod.id to server
    await formSubmit(formikValues, result.paymentMethod.id, paymentMethodType);
  };

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {(formik: FormikProps<FormValues>) => {
        return (
          <Form acceptCharset="UTF-8" method="post" id="transaction-form">
            <SnackbarAlert
              open={isAlertOpen}
              severity="error"
              onClose={handleOnAlertDismiss}
              content={alertMessage}
              $muiAlert
            />
            {AuthenticityToken()}
            <StripeAddress
              shippingRequired={shippingRequired}
              googleMapsApiKey={googleMapsApiKey}
              formik={formik}
              emailAddress={emailAddress}
              currentUser={currentUser}
              billingProps={{
                setBillingAddress,
                isBillingSameAsShipping,
                billingAddress,
                setIsBillingSameAsShipping,
                setBillingError,
              }}
              handleOnChangeRef={handleOnChangeRef}
            />
            <SectionDivide mt={isBillingSameAsShipping ? 1 : 3} mb={4} />
            <Section>
              <Header>Payment</Header>
              <PaymentElement
                id="payment-element"
                options={{
                  defaultValues: {
                    billingDetails: billingAddress,
                  },
                  layout: {
                    type: "accordion",
                    defaultCollapsed: false,
                    radios: true,
                    spacedAccordionItems: false,
                  },
                }}
                onChange={() => setLoading(false)}
              />
              <Box>
                <SectionDivide mb={3} mt={2} />
                <Header>Order Summary</Header>
                <PriceBreakdown {...orderSummaryProps} />
              </Box>
              <Box mt={2} width="100%">
                <SubmitButton
                  id="send-add-card"
                  name="send-add-card"
                  type="submit"
                  disabled={formik.isSubmitting || !stripeObject || loading || billingError}
                >
                  {formik.isSubmitting || !stripeObject || loading ? (
                    <LoadingIndicator fullscreen={true} />
                  ) : (
                    "PAY NOW"
                  )}
                </SubmitButton>
              </Box>
            </Section>
            <Box mt={2} width="100%">
              <PaymentInfo>
                Your payment is securely processed by Stripe. AirRobe does not store your
                credit/debit card information.
                <br />
                <br />
                You will be charged only if {authorName} accepts the transaction. {authorName} needs
                to accept the transaction within 3 days. If {authorName} declines or does not
                respond, no charge is made.
              </PaymentInfo>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
}

const SubmitButton = styled(Button)`
  width: 100%;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.8px;
`;

const PaymentInfo = styled(Typography)`
  color: #666;
  font-family: Arial, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
