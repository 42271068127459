import classNames from "classnames";
import { a, div, span } from "r-dom";
import { Component, MouseEvent, ReactNode } from "react";
import css from "./MenuItem.module.css";

export interface Props {
  active?: boolean;
  extraClasses?: string;
  extraClassesLink?: string;
  content?: ReactNode;
  href?: string;
  textColor?: string;
  type: string;
  external?: boolean;
  onClick?: (event: MouseEvent) => void;
}

class MenuItem extends Component<Props> {
  constructor(props, context) {
    super(props, context);

    this.textColor = this.props.active ? "#4a4a4a" : this.props.textColor || "#4a90e2";
  }

  textColor: string;

  render() {
    const extraClasses = this.props.extraClasses ? this.props.extraClasses : "";
    const extraClassesLink = this.props.extraClassesLink ? this.props.extraClassesLink : "";
    const inlineStyling = this.props.textColor != null ? { style: { color: this.textColor } } : {};
    const linkProps = {
      className: `MenuItem_link ${css.menuitemLink} ${extraClassesLink}`,
      href: this.props.href,
      target: this.props.external ? "_blank" : null,
      rel: this.props.external ? "noopener noreferrer" : null,
      onClick: this.props.onClick,
      ...inlineStyling,
    };

    const renderContainerFn = linkProps.href ? a : div;

    return div({ className: classNames("MenuItem", css.menuitem, extraClasses) }, [
      this.props.active
        ? span({
            className: css.activeIndicator,
          })
        : null,
      renderContainerFn(linkProps, this.props.content),
    ]);
  }
}

export default MenuItem;
