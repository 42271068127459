import { gql } from "@apollo/client";
import { CollectionCardFragment } from "../../../../graphql/fragments/collections";

export default gql`
  query GetListingCollections($listingId: Int!) {
    listing(id: $listingId) {
      collections {
        ...CollectionCardFragment
      }
    }
    me {
      collections(perPage: 1000) {
        results {
          ...CollectionCardFragment
        }
      }
    }
  }
  ${CollectionCardFragment}
`;
