import React, { useEffect, useMemo, useState } from "react";
import { AddressElement, LinkAuthenticationElement } from "@stripe/react-stripe-js";
import {
  DefaultValuesOption,
  StripeAddressElementChangeEvent,
  StripeAddressElementOptions,
} from "@stripe/stripe-js";
import { FormikProps } from "formik";
import { FormValues, UserProps } from "../CheckoutPage";
import { Section } from "../elements/Section";
import Header from "../elements/Header";
import Box from "../../../elements/v2/Box/Box";
import BillingAddress from "./BillingAddress";
import { Checkbox } from "@material-ui/core";
import { Span } from "../../../elements/v2/Typography/Typography";
import styled from "styled-components";

export type SetBillingAddressType = (
  address:
    | React.Dispatch<
        React.SetStateAction<
          StripeAddressElementOptions["defaultValues"] | DefaultValuesOption["billingDetails"]
        >
      >
    | StripeAddressElementOptions["defaultValues"]
    | DefaultValuesOption["billingDetails"]
) => void;

interface BillingProps {
  setBillingAddress: SetBillingAddressType;
  isBillingSameAsShipping: boolean;
  billingAddress:
    | StripeAddressElementOptions["defaultValues"]
    | DefaultValuesOption["billingDetails"];
  setIsBillingSameAsShipping: (any: boolean) => void;
  setBillingError: (any: boolean) => void;
}

export default function StripeAddress({
  shippingRequired,
  googleMapsApiKey,
  formik,
  emailAddress,
  currentUser,
  billingProps,
  handleOnChangeRef,
}: {
  formik: FormikProps<FormValues>;
  shippingRequired: boolean;
  googleMapsApiKey: string;
  emailAddress: string;
  currentUser: UserProps;
  billingProps: BillingProps;
  handleOnChangeRef: React.MutableRefObject<(event: StripeAddressElementChangeEvent) => void>;
}) {
  const [addressFields, setAddressFields] = useState({
    "shipping_address[name]": "",
    "shipping_address[street1]": "",
    "shipping_address[street2]": "",
    "shipping_address[city]": "",
    "shipping_address[state_or_province]": "",
    "shipping_address[country]": "",
    "shipping_address[postal_code]": "",
  });

  const handleOnChange = (event: StripeAddressElementChangeEvent) => {
    if (event.complete && shippingRequired) {
      // Extract potentially complete address
      // Mapping fields here helps preserve existing logic in our backend
      const address = event.value.address;
      addAddressField("name", event.value.name);
      addAddressField("street1", address.line1);
      addAddressField("street2", address.line2);
      addAddressField("city", address.city);
      addAddressField("state_or_province", address.state);
      addAddressField("country", address.country);
      addAddressField("postal_code", address.postal_code);
      if (isBillingSameAsShipping)
        setBillingAddress({
          name: event.value.name,
          address: address,
        });
    }
  };

  useEffect(() => {
    handleOnChangeRef.current = handleOnChange;
  }, [handleOnChangeRef]);

  const {
    setIsBillingSameAsShipping,
    isBillingSameAsShipping,
    setBillingAddress,
    setBillingError,
    billingAddress,
  } = billingProps;

  const addAddressField = (field: string, value: string) => {
    setAddressFields((prevState) => {
      return { ...prevState, [`shipping_address[${field}]`]: value };
    });
    formik.setFieldValue(`shipping_address[${field}]`, value);
  };

  const defaultAddressValues = useMemo(() => {
    let values: StripeAddressElementOptions["defaultValues"] = {
      name: currentUser.name,
    };
    if (currentUser.address)
      values = {
        ...values,
        address: {
          line1: currentUser.address.street1 || "",
          country: currentUser.address?.country,
          line2: currentUser.address.street2 || "",
          city: currentUser.address.city || "",
          state: currentUser.address.state_or_province,
          postal_code: currentUser.address.postal_code || "",
        },
      };
    return values;
  }, []);

  const handleCheckboxChange = (event) => {
    setIsBillingSameAsShipping(event.target.checked);
    event.target.checked && setBillingError(false);
  };

  return (
    <Section>
      <Header>{shippingRequired ? "Shipping " : "Billing "}Address</Header>
      <Box mb={1} width="100%">
        <LinkAuthenticationElement
          options={{
            defaultValues: {
              email: emailAddress,
            },
          }}
        />
      </Box>
      <Box id="address-element" mb={2}>
        <AddressElement
          onChange={handleOnChange}
          options={{
            mode: "billing",
            autocomplete: {
              mode: "google_maps_api",
              apiKey: googleMapsApiKey,
            },
            defaultValues: defaultAddressValues,
          }}
        />
      </Box>
      {shippingRequired && (
        <CheckboxText>
          <Checkbox checked={isBillingSameAsShipping} onChange={handleCheckboxChange} />
          Billing address is the same as shipping address.
        </CheckboxText>
      )}
      {!isBillingSameAsShipping && (
        <BillingAddress
          defaultValues={defaultAddressValues}
          {...{ setBillingAddress, setBillingError, googleMapsApiKey, billingAddress }}
        />
      )}
      {Object.entries(addressFields).map((field) => (
        <input
          key={field[0]}
          id={field[0]}
          name={field[0]}
          type={"hidden"}
          value={field[1] || ""}
        />
      ))}
    </Section>
  );
}

const CheckboxText = styled(Span)`
  font-size: 12px;
`;
