import React, { useEffect, useState } from "react";
import CampaignModal, {
  CallToAction,
  Description as DefaultDescription,
  Heading,
} from "./CampaignModal";
import { SIGNUP_CAMPAIGN } from "../../../constants/CampaignModalConstants";
import AirRobeLogoSVG from "../../../assets/airrobe.svg";
import styled from "styled-components";
import GoogleSignUpButton from "../../composites/v2/GoogleSSO/GoogleSignUpButton";
import axios from "axios";
import { getCsrfHeaders } from "../../../utils/csrfHelper";
import SnackbarAlert from "../../composites/v2/SnackbarAlert/SnackbarAlert";
import Events from "../../../utils/analytics";
import { SignupForm } from "./SignupFormForModal";

interface SignupProps {
  clickOpened?: boolean;
  onClose?: () => void;
}

export default function SignupCampaign({ clickOpened, onClose }: SignupProps) {
  const campaignName = SIGNUP_CAMPAIGN.NAME;
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const alertMessage = "An error has occurred trying to sign up";

  const handleOnAlertDismiss = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setIsAlertOpen(false);
  };

  const handleOnSignupPress = async ({
    email,
    password,
    givenName,
    familyName,
    terms,
    marketingOptIn,
  }) => {
    Events.sendCampaignModalClicked({
      campaignName,
      formFields: {
        givenName,
        familyName,
        email,
        marketingOptIn,
      },
    });

    const formData = new FormData();
    formData.append("person[email]", email);
    formData.append("person[given_name]", givenName);
    formData.append("person[family_name]", familyName);
    formData.append("person[password]", password);
    formData.append("person[password2]", password);
    formData.append("person[terms]", terms ? "on" : "off");
    formData.append("person[admin_emails_consent]", marketingOptIn ? "on" : "off");

    try {
      await axios({
        method: "post",
        url: "/people",
        data: formData,
        headers: getCsrfHeaders(),
        withCredentials: true,
      });

      window.location.assign(`/confirmation_pending`);
    } catch (error) {
      console.error(error.message);
      setIsAlertOpen(true);
    }
  };

  const handleLoginPress = () => {
    Events.sendCampaignModalClicked({
      campaignName,
      link: "/login",
    });
  };

  useEffect(() => {
    const handleLoad = () => {
      const zendesk = document.getElementById("launcher");
      zendesk.style.display = "none";
    };
    window.addEventListener("load", handleLoad);
    return () => {
      window.removeEventListener("load", handleLoad);
    };
  });

  return (
    <CampaignModal
      name={campaignName}
      renderDelaySeconds={SIGNUP_CAMPAIGN.RENDER_DELAY_SECONDS}
      hideForDaysOnClose={SIGNUP_CAMPAIGN.HIDE_ON_CLOSE_DAYS}
      maxWidth={"391px"}
      clickOpened={clickOpened}
      onClose={onClose}
    >
      <Content>
        <SnackbarAlert
          open={isAlertOpen}
          severity="error"
          onClose={handleOnAlertDismiss}
          content={alertMessage}
        />
        <Title>
          <AirRobeLogo />
        </Title>
        <Description>Sign up for 10% off your first purchase.</Description>
        <CallToAction>
          <GoogleSignUpButton width={"100%"} />
          <SignupForm onSignupPress={handleOnSignupPress} onLoginPress={handleLoginPress} />
        </CallToAction>
      </Content>
    </CampaignModal>
  );
}

const Title = styled(Heading)`
  text-transform: uppercase;
  font-size: 14px;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  padding-bottom: 0;
`;

const AirRobeLogo = styled.div.attrs({
  dangerouslySetInnerHTML: {
    __html: AirRobeLogoSVG,
  },
})`
  > svg {
    width: 194px;
    fill: black;
  }
  height: 42px;
`;

const Description = styled(DefaultDescription)`
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 32px;
`;
