import React from "react";
import styled from "styled-components";
import Box, { BoxProps } from "../../../elements/v2/Box/Box";
import { ListingData } from "../../../../utils/listings";
import Slider, { Settings } from "react-slick";
import ListingCard from "../ListingCard/ListingCard";
import { breakpoints } from "../../../../utils/styledHelpers";

const defaultSliderSettings: Settings = {
  infinite: true,
  speed: 300,
  slidesToShow: 4,
  slidesToScroll: 4,
  autoplay: true,
  autoplaySpeed: 5000,
  responsive: [
    {
      breakpoint: 768,
      settings: "unslick",
    },
  ],
};

export type ListingSliderProps = BoxProps & {
  listings: ListingData[];
  sliderSettings?: Settings;
  handleListingClick?: (listing: ListingData) => void;
};

function ListingSlider({
  listings,
  sliderSettings,
  handleListingClick,
  ...props
}: ListingSliderProps) {
  const settings = {
    ...defaultSliderSettings,
    ...sliderSettings,
  };

  const sliderDisabled = listings.length <= 4;

  const makeClickHandler = (listing) => () => {
    handleListingClick(listing);
  };

  return (
    <SliderContainer className={sliderDisabled && "unslick"} {...props}>
      <Slider {...settings}>
        {listings.map((listing) => (
          <ListingCard key={listing.id} listing={listing} handleClick={makeClickHandler(listing)} />
        ))}
      </Slider>
    </SliderContainer>
  );
}

export default ListingSlider;

const SliderContainer = styled(Box)`
  &.unslick .slick-cloned {
    display: none;
  }
  .slick-slider {
    position: relative;
    .slick-slide > div {
      height: 100%;
    }
    .slick-list {
      overflow: hidden;
      padding-bottom: 10px;
      z-index: 0;
      .slick-track {
        display: flex;
        gap: 10px;
      }
    }
    > button.slick-arrow {
      position: absolute;
      top: 50%;
      z-index: 1;
      background: black;
      cursor: pointer;
      font-size: 0;
      color: transparent;
      display: block;
      width: 24px;
      height: 24px;
      padding: 0;
      transform: translateY(-50%);
      border: none;
      &:before {
        font-family: "FontAwesome", serif;
        color: white;
        font-size: 20px;
        line-height: 24px;
      }
      &.slick-prev {
        &:before {
          // .icon-angle-left
          content: "\\f104";
        }
        left: -12px;
      }
      &.slick-next {
        &:before {
          // .icon-angle-right
          content: "\\f105";
        }
        right: -12px;
      }

      &.slick-next,
      &.slick-next:focus,
      &.slick-next:hover,
      &.slick-prev,
      &.slick-prev:focus,
      &.slick-prev:hover {
        outline: none;
      }
    }
  }

  ${breakpoints.down("tablet")} {
    div.slider {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-row: auto / auto;
      grid-row-gap: 20px;
      grid-column-gap: 20px;

      > * {
        display: none;
        &:nth-child(-n + 6) {
          display: block;
        }
      }
    }
  }
`;
