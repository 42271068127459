import React, { useMemo } from "react";
import { Pipeline, useSearch } from "@sajari/react-hooks";
import pipelineConfigClient from "../../../../../utils/search/pipelineConfigClient";
import { ListingData, mapResultToListing } from "../../../../../utils/listings";
import styled from "styled-components";
import Box from "../../../../elements/v2/Box/Box";
import { H2 } from "../../../../elements/v2/Typography/Heading";
import { LoadingSpinnerCentered } from "../../../../elements/v2/LoadingSpinner/LoadingSpinner";
import ListingSlider from "../../../../composites/v2/ListingSlider/ListingSlider";
import { Variables } from "@sajari/react-hooks/dist/ContextProvider/controllers/Variables";

type Props = {
  heading: string;
  variables: Variables;
  handleListingClick?: (listing: ListingData) => void;
};

const MINIMUM_NUMBER_ITEMS_TO_SHOW_SLIDER = 2;

export default function FilteredProductSlider({ heading, variables, handleListingClick }: Props) {
  const pipeline = useMemo(() => new Pipeline(pipelineConfigClient, "app"), []);
  const { results = [], searching } = useSearch({ variables, pipeline });

  if (!searching && results.length < MINIMUM_NUMBER_ITEMS_TO_SHOW_SLIDER) return null;

  const listings = results.map((result) => mapResultToListing(result, []));

  return (
    <Box py={4}>
      <Heading>{heading}</Heading>
      {searching ? (
        <LoadingSpinnerCentered />
      ) : (
        <ListingSlider mt={4} listings={listings} handleListingClick={handleListingClick} />
      )}
    </Box>
  );
}

const Heading = styled(H2)`
  font-family: Lora, serif;
`;
