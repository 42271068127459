import React from "react";
import styled from "styled-components";
import {
  GetListing_listing,
  GetListing_listing_customFields_value_CustomCheckboxFieldValue,
  GetListing_listing_customFields_value_CustomCheckboxFieldValue_selectedOptions,
} from "../../queries/types/GetListing";
import Box, { BoxProps } from "../../../../elements/v2/Box/Box";
import Typography from "../../../../elements/v2/Typography/Typography";
import { CUSTOM_FIELD_KEYS } from "../../../../../constants/CustomFieldConstants";

type Props = BoxProps & {
  listing?: GetListing_listing;
};

type SizeOption = GetListing_listing_customFields_value_CustomCheckboxFieldValue_selectedOptions;
type CheckboxFieldValue = GetListing_listing_customFields_value_CustomCheckboxFieldValue;

function getSizeFieldValue(listing: GetListing_listing): CheckboxFieldValue {
  let value = null;
  value ??= listing.customFields.find((f) => f.key === CUSTOM_FIELD_KEYS.SIZE_GUIDE)?.value;
  value ??= listing.customFields.find((f) => f.key === CUSTOM_FIELD_KEYS.SHOE_SIZE)?.value;
  value ??= listing.customFields.find((f) => f.key === CUSTOM_FIELD_KEYS.ACCESSORIES_SIZE)?.value;
  value ??= listing.customFields.find((f) => f.key === CUSTOM_FIELD_KEYS.PANTS_SIZE)?.value;
  value ??= listing.customFields.find((f) => f.key === CUSTOM_FIELD_KEYS.LABEL_SIZE)?.value;

  return value as CheckboxFieldValue;
}

function getCustomFieldSizeValues(listing: GetListing_listing): SizeOption[] | null {
  const value = getSizeFieldValue(listing);
  if (!value?.selectedOptions) return null;

  return value.selectedOptions;
}

export function getCustomFieldSize(listing: GetListing_listing): string | null {
  const selectedOptions = getCustomFieldSizeValues(listing);
  if (!selectedOptions) return null;

  return selectedOptions.map((option: SizeOption) => option.title).join(", ");
}

export default function Size({ listing, ...props }: Props) {
  const sizeValue = getCustomFieldSize(listing);

  return (
    sizeValue && (
      <Box {...props}>
        <Content>Size: {sizeValue}</Content>
      </Box>
    )
  );
}

const Content = styled(Typography)`
  display: inline-flex;
  gap: 5px;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  color: #222222;
`;
