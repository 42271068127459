import { useFilter } from "@sajari/react-hooks";
import { xor } from "lodash";
import React from "react";
import FilterChip from "../FilterChip";

export default function DealsFilter() {
  const { setSelected, options, selected } = useFilter("price_reduced_percentage_bucket");
  function handlePriceDropsClick(label: string) {
    setSelected(xor(selected, [label]));
  }

  return (
    <div className="t-searchFilterGroup">
      {options.map(({ label, value }) => {
        if (!label) return null;

        return (
          <FilterChip
            key={value}
            label={label}
            onClick={() => handlePriceDropsClick(label)}
            $selected={selected.includes(label)}
            size="small"
          />
        );
      })}
    </div>
  );
}
