import { v4 as uuidv4 } from "uuid";
import { CurrencyCode } from "../../../../../types/graphql-global-types";
import { GetListingFormData_listing } from "../../queries/types/GetListingFormData";
import { DEFAULT_SHIPPING_PRICE } from "../../../../../constants/ShippingConstants";

interface NameFormField {
  name: string;
}

interface ErrorFormField {
  requiredErrorMsg: string;
}
interface LabelFormField {
  label: string;
}

interface InvalidMessageFormField {
  invalidErrorMsg: string;
}

interface MinAmountErrFunct {
  minAmountErrorMsg: (minimumPrice: number) => string;
}

interface ImageUploadMsg {
  imageUploadingMsg: string;
}

type NameLabelErrorFormField = NameFormField & LabelFormField & ErrorFormField;

type WithInvalidMessageFormField = NameLabelErrorFormField & InvalidMessageFormField;

export interface RentFormField {
  rentalPeriod: NameLabelErrorFormField;
  dryCleaning: NameLabelErrorFormField;
  tryOnAvailability: NameLabelErrorFormField;
}

export interface ResellFormField {
  enableSmartPricing: NameFormField & LabelFormField;
  floorPrice: WithInvalidMessageFormField;
}

export interface SharedFormField {
  condition: NameLabelErrorFormField;
  conditionNotes: NameLabelErrorFormField;
  currency: NameLabelErrorFormField;
  location: NameLabelErrorFormField;
  shippingFee: WithInvalidMessageFormField;
  shippingFeeInternational: WithInvalidMessageFormField;
  price: MinAmountErrFunct & WithInvalidMessageFormField;
  images: NameLabelErrorFormField & ImageUploadMsg;
  includeShippingFee: NameFormField & LabelFormField;
  includeDomesticShippingFee: NameFormField & LabelFormField;
  includeInternationalShippingFee: NameFormField & LabelFormField;
  deliveryMethods: NameFormField & ErrorFormField;
}

export type ResellFormFields = SharedFormField & ResellFormField;
export type RentFormFields = SharedFormField & RentFormField;
export interface RentFormListingFormModel {
  formId: string;
  formField: RentFormFields;
}

const form: RentFormListingFormModel = {
  formId: "listingForm",
  formField: {
    condition: {
      name: "condition",
      label: "Condition",
      requiredErrorMsg: "Condition is required",
    },
    conditionNotes: {
      name: "conditionNotes",
      label: "Condition notes",
      requiredErrorMsg: "Condition notes are required",
    },
    currency: {
      name: "currency",
      label: "Currency",
      requiredErrorMsg: "Currency is required",
    },
    location: {
      name: "location",
      label: "Your location",
      requiredErrorMsg: "Location is required",
    },
    shippingFee: {
      name: "shippingFee",
      label: "Shipping Fee",
      requiredErrorMsg: "Shipping Fee is required",
      invalidErrorMsg: "Shipping Fee is invalid",
    },
    shippingFeeInternational: {
      name: "shippingFeeInternational",
      label: "International Shipping Fee",
      requiredErrorMsg: "International Shipping Fee is required",
      invalidErrorMsg: "International Shipping Fee is invalid",
    },
    price: {
      name: "price",
      label: "Price",
      requiredErrorMsg: "Price is required",
      invalidErrorMsg: "Price is invalid",
      minAmountErrorMsg: (minimumPrice: number) =>
        `Price must be greater than or equal to ${minimumPrice}`,
    },
    images: {
      name: "images",
      label: "Images",
      requiredErrorMsg: "At least one image is required",
      imageUploadingMsg: "Images are still uploading, please wait",
    },
    deliveryMethods: {
      name: "deliveryMethods",
      requiredErrorMsg: "Delivery method is required",
    },
    includeShippingFee: {
      name: "includeShippingFee",
      label: "Include shipping fee in price?",
    },
    includeDomesticShippingFee: {
      name: "includeDomesticShippingFee",
      label: "Offer free domestic shipping",
    },
    includeInternationalShippingFee: {
      name: "includeInternationalShippingFee",
      label: "Offer international shipping at domestic rate",
    },
    tryOnAvailability: {
      name: "tryOnAvailability",
      label: "Try-on availability",
      requiredErrorMsg: "Try-on availability option is required",
    },
    rentalPeriod: {
      name: "rentalPeriod",
      label: "Rental period",
      requiredErrorMsg: "Rental period is required",
    },
    dryCleaning: {
      name: "dryCleaning",
      label: "Dry Cleaning",
      requiredErrorMsg: "Dry cleaning option is required",
    },
  },
};

export default form;

// Given the data from the server, return initial values for the form model
export function getInitialValues(
  listing: GetListingFormData_listing,
  initialCurrency: CurrencyCode
) {
  return {
    condition: listing.condition,
    conditionNotes: listing.conditionNotes || "",
    currency: listing.price ? listing.price.currency.code : initialCurrency,
    price: listing.price ? listing.price.formatted : "",
    rentalPeriod: listing.unitType || "",
    shippingFee:
      listing.shippingPrice?.fractional > 0
        ? listing.shippingPrice.formatted
        : DEFAULT_SHIPPING_PRICE.LOCAL,
    shippingFeeInternational:
      listing.shippingPriceInternational?.fractional > 0
        ? listing.shippingPriceInternational.formatted
        : DEFAULT_SHIPPING_PRICE.INTERNATIONAL,
    includeShippingFee:
      !listing.shippingPrice?.fractional && !listing.shippingPriceInternational?.fractional,
    includeDomesticShippingFee:
      listing.shippingPrice === null ? false : !(listing.shippingPrice?.fractional > 0),
    includeInternationalShippingFee:
      listing.shippingPriceInternational === null
        ? false
        : !(listing.shippingPriceInternational?.fractional > 0),
    tryOnAvailability: listing.tryOnAvailability,
    dryCleaning: listing.dryCleaning,
    deliveryMethods: listing.deliveryMethods,
    location: listing.location || "",
    images: listing.listingImages.map((image) => ({
      uuid: uuidv4(),
      listingImageId: image.id,
      processedURL: image.squareUrl,
      doneUploading: true,
      doneProcessing: image.ready,
    })),
  };
}
