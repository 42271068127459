import { useLazyQuery, useMutation } from "@apollo/client";
import classNames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { MyCollectionsContext } from "../../../contexts/MyCollectionsContext/MyCollectionsContext";
import { ListingCardFragment } from "../../../graphql/fragments/types/ListingCardFragment";
import followRedirects from "../../../utils/followRedirectsWithReturnPath";
import Dropdown from "../../composites/Dropdown/Dropdown";
import MenuContent from "../../composites/Menu/MenuContent";
import FavouriteIcon from "../../elements/FavouriteIcon/FavouriteIcon";
import Modal from "../../elements/Modal/Modal";
import { CreateCollection } from "../NewCollectionForm/mutations/types/CreateCollection";
import NewCollectionForm from "../NewCollectionForm/NewCollectionForm";
import css from "./ListingCollectionsDropdown.module.css";
import TOGGLE_COLLECTION_LISTING from "./mutations/ToggleListingCollection";
import {
  ToggleListingCollection,
  ToggleListingCollectionVariables,
} from "./mutations/types/ToggleListingCollection";
import GET_LISTING_COLLECTIONS from "./queries/GetListingCollections";
import {
  GetListingCollections,
  GetListingCollectionsVariables,
} from "./queries/types/GetListingCollections";
import buildMenuContent from "./utils/buildMenuContent";
import { ListingData } from "../../../utils/listings";

export interface Props {
  listing: Partial<ListingCardFragment> | ListingData;
  className?: string;
}

// Renders a favourite icon, with a dropdown of collections. When the user clicks the icon, it adds
// the listing to the default collection, and shows the dropdown with a list of all collections.
// On click, open the dropdown, and toggle the collection membership in the default collection
//
// We get all the user's collections, plus the listing collections, in two separate queries. The
// first (all collections) are provided by the MyCollectionsContext, and the second (listing
// collections) are provided by the getListingCollections query when the user opens the dropdown.
export default function ListingCollectionsDropdown({ listing, className }: Props) {
  const myCollectionsContext = useContext(MyCollectionsContext);
  const [isCollectionFormVisible, setCollectionFormVisible] = useState(false);
  const [isInMyCollections, setInMyCollections] = useState(listing.isInMyCollections);

  useEffect(() => {
    setInMyCollections(listing.isInMyCollections);
  }, [listing.isInMyCollections]);

  const handleClose = () => setCollectionFormVisible(false);

  const [
    getListingCollections,
    { data: listingCollectionsData, refetch: refetchListingCollections },
  ] = useLazyQuery<GetListingCollections, GetListingCollectionsVariables>(GET_LISTING_COLLECTIONS, {
    variables: { listingId: listing.id },
  });

  // Get all the user's collections.
  // TODO: I first implemented this using a context (MyCollectionsContext), which means that
  // collections are fetched once for the page, then available to every component via context.
  // However, this is not a viable approach for our Rails-rendered listing cards, which are rendered
  // each in their own app. Instead of implementing another context for those cards, instead we also
  // request all collections in the listingCollections query. We could use this data exclusively
  // without using the MyCollectionsContext at all, but we keep it in place despite the added
  // complexity as I think it will be a better approach for the future.
  const allCollections =
    (listingCollectionsData || myCollectionsContext.data)?.me?.collections.results || [];

  // We then build menu items using these two data sets to show all collections, with listing
  // collections highlighted using the 'active' prop.
  const menuContent = buildMenuContent({
    allCollections,
    listingCollections: listingCollectionsData?.listing.collections || [],
    handleCollectionClick: (collection: any) =>
      toggleListingCollection({
        variables: {
          input: { listingId: listing.id, collectionId: collection.id },
        },
      }),
    handleNewCollectionClick: () => setCollectionFormVisible(true),
  });

  const [toggleListingCollection, { data: mutationData }] = useMutation<
    ToggleListingCollection,
    ToggleListingCollectionVariables
  >(TOGGLE_COLLECTION_LISTING, {
    variables: { input: { listingId: listing.id } },

    // This should not be necessary! The apollo cache should be able to merge the incoming
    // "collections" field of the listing from the result of this mutation, and update the cached
    // result of the getListingCollections query, triggering a re-render. However, this isn't
    // currently happening, and triggering a refetch solves the problem (although it's an inferior)
    // user experience as there is a delay between the click and the UI updating.
    onCompleted: ({ toggleListingCollection: response }) => {
      if (!response.listing) {
        return;
      }

      if (response.isInCollection) {
        // The listing is in this collection
        showSuccessMessage({ collection: response.collection });
      }

      // The response is in any of my collections - set the heart active state accordingly
      setInMyCollections(response.listing.isInMyCollections);
      refetchListingCollections();
    },
  });

  // If the mutation responds with a "redirectTo" value, fasten your seatbelts
  followRedirects(mutationData?.toggleListingCollection.redirectTo);

  function showSuccessMessage({ collection }) {
    (window as any).FlashMessage.success(
      `Added to ${collection.displayTitle}<br/><a href="${collection.url}">View collection</a>`
    );
  }

  // We lazily load the collections for a listing, only when the user clicks the favourite icon to
  // open the dropdown.
  function handleFavouriteIconClick() {
    getListingCollections();
  }

  // Refetch queries and hide the form - again, the refetching strategy is sub-optimal, but ok for
  // now
  function afterCollectionCreate({ createCollection }: CreateCollection) {
    refetchListingCollections();
    setCollectionFormVisible(false);
    if (createCollection.collection) {
      showSuccessMessage({
        collection: createCollection.collection,
      });
    }
  }

  return (
    <Dropdown
      className={classNames(className, "t-favouriteIcon")}
      handleComponent={
        <FavouriteIcon
          size={20}
          handleClick={handleFavouriteIconClick}
          isActive={isInMyCollections}
        />
      }
    >
      <MenuContent content={menuContent} className={css.menuContent} />
      {isCollectionFormVisible ? (
        <Modal handleClose={handleClose}>
          <NewCollectionForm
            listingIds={[listing.id]}
            afterCreate={afterCollectionCreate}
            handleCancel={handleClose}
          />
        </Modal>
      ) : null}
    </Dropdown>
  );
}
