import React from "react";
import BrandHeader from "./components/BrandHeader";
import { getSsrData, putSsrData } from "../../../utils/PersistSSR";
import { search } from "@sajari/server";
import { createEntryPage } from "../FullPageLayout/FullPageLayout";
import {
  eventTracking,
  getSearchProviderPipelineConfig,
} from "../../../utils/search/SearchContext";
import "cross-fetch/polyfill";
import { Pipeline } from "@sajari/react-hooks";
import Box from "../../elements/v2/Box/Box";
import { FilterProps } from "../../../utils/filters";
import SearchHeader from "./components/SearchHeader";
import { SearchPageProvider } from "./SearchPageContext";
import { MyCollectionsProvider } from "../../../contexts/MyCollectionsContext/MyCollectionsContext";
import { Route, Switch } from "react-router-dom";
import ProfileReviewsPage from "./components/ProfilePage/ProfileReviewsPage";
import CollectionsBlock from "./components/ProfilePage/components/CollectionsBlock/CollectionsBlock";
import ProfileContactPage from "./components/ProfilePage/ProfileContactPage";
import styled from "styled-components";
import { SellerType } from "../../../types/graphql-global-types";
import FlexBox from "../../elements/v2/Box/FlexBox";
import SmartImage from "../../composites/SmartImage/SmartImage";
import InfoBox from "./components/ProfilePage/components/InfoBox/InfoBox";
import ListingsEngagementBlock from "./components/ProfilePage/components/ListingsEngagementBlock/ListingsEngagementBlock";
import { breakpoints } from "../../../utils/styledHelpers";
import SearchContent from "./components/SearchContent";

function optionalizeLocale(path) {
  return path.replace(/^\/en\//, "*/");
}

export type SearchPageProps = FilterProps & {
  featuredBrandLogoUrl?: string;
  featuredBrandHeaderUrl?: string;
};

function SearchPage({
  featuredBrandLogoUrl,
  featuredBrandHeaderUrl,
  brand,
  profilePage,
  collectionId,
  isProfilePage,
  longTailPage,
  discountEligibilityPage: discountEligibilityPage,
  conciergePage,
}: SearchPageProps) {
  const isProfessionalSeller =
    profilePage && profilePage.person.sellerType === SellerType.professional_seller;
  const showHeaderImage = isProfessionalSeller && profilePage.person.coverImageUrl;
  const showEngagements = profilePage && (profilePage.person.isMe || profilePage.me?.isAdmin);

  return (
    <MyCollectionsProvider>
      <SearchPageProvider
        collectionId={collectionId}
        isProfilePage={isProfilePage}
        profilePage={profilePage}
        longTailPage={longTailPage}
        discountEligibilityPage={discountEligibilityPage}
        conciergePage={conciergePage}
      >
        <Wrapper>
          {showHeaderImage && (
            <Header>
              <BackgroundImage imageUrl={profilePage.person.coverImageUrl} />
            </Header>
          )}
          {(profilePage || conciergePage) && (
            <Section>
              <InfoBox profilePage={profilePage} conciergePage={conciergePage} />
              {showEngagements && <ListingsEngagementBlock />}
            </Section>
          )}
          <Switch>
            {profilePage && (
              <Route exact path={optionalizeLocale(profilePage.person.reviewsUrl)}>
                <Section>
                  <ProfileReviewsPage />
                </Section>
              </Route>
            )}
            {profilePage && (
              <Route exact path={optionalizeLocale(profilePage.person.collectionsUrl)}>
                <Section>
                  <CollectionsBlock pageParam="c" />
                </Section>
              </Route>
            )}
            {profilePage && (
              <Route exact path={optionalizeLocale(profilePage.person.contactUrl)}>
                <Section>
                  <ProfileContactPage />
                </Section>
              </Route>
            )}
            <Route path="*">
              <Box pt={3} px={[0, 2]}>
                {brand && (
                  <BrandHeader
                    brand={brand}
                    featuredBrandHeaderUrl={featuredBrandHeaderUrl}
                    featuredBrandLogoUrl={featuredBrandLogoUrl}
                  />
                )}
                <SearchHeader />
                <SearchContent />
              </Box>
            </Route>
          </Switch>
        </Wrapper>
      </SearchPageProvider>
    </MyCollectionsProvider>
  );
}

const Wrapper = styled.div`
  width: 100%;
  padding-bottom: 24px;
`;

const Header = styled(FlexBox)`
  width: 100%;
  max-height: 196px;
  overflow: hidden;
  background: #f8eee7;
  padding: 0;
  margin: 0;
  align-items: center;
`;

const BackgroundImage = styled(SmartImage)`
  width: 100%;
`;

const Section = styled(Box)`
  width: 100%;
  padding: 24px;
  ${breakpoints.up("sm")} {
    padding-left: 40px;
    padding-right: 40px;
  }}
`;

export interface Args {
  topbarProps: any;
  searchProps: SearchPageProps;
}

function productLongTailPagePrep({ longTailPage }: SearchPageProps, initialResponse: string) {
  const parsedInitialResponse = JSON.parse(initialResponse);
  if (!longTailPage || !parsedInitialResponse.response) return;

  let productLongTailPageItemIdsArray = [];
  const searchResultsTotal = parsedInitialResponse.response.totalResults;
  if (longTailPage.isProductLongTailPage && searchResultsTotal < 40) {
    productLongTailPageItemIdsArray = parsedInitialResponse.response.results.map(
      (item: Record<string, { id: string }>) => item.values.id
    );
    longTailPage.productLongTailPageItemIds = productLongTailPageItemIdsArray;
  }
}

const mapClientEntryProps = (args: Args) => {
  const { searchProps } = args;
  const searchConfig = getSearchProviderPipelineConfig(searchProps, window.location.search);
  const initialResponse = getSsrData("sajariInitialResponse");

  productLongTailPagePrep(searchProps, initialResponse);

  return {
    pageProps: {
      ...args,
      searchProviderProps: {
        initialResponse,
        search: searchConfig,
        syncUrlState: true,
        searchOnLoad: !initialResponse,
      },
    },
    componentProps: searchProps,
  };
};

const mapServerEntryProps = async (args: Args, context: { search: string }) => {
  const { searchProps } = args;
  // This file needs to be dynamically imported to hide secrets from the client bundle
  const { default: pipelineConfigServer } = await import(
    "../../../utils/search/pipelineConfigServer"
  );
  const serverPipeline = new Pipeline(pipelineConfigServer, "app", eventTracking);
  const searchConfig = getSearchProviderPipelineConfig(searchProps, context.search, serverPipeline);
  const initialResponse = await search(searchConfig);

  putSsrData("sajariInitialResponse", initialResponse);

  return {
    pageProps: {
      ...args,
      searchProviderProps: {
        initialResponse,
        search: searchConfig,
      },
    },
    componentProps: searchProps,
  };
};

export default createEntryPage(mapClientEntryProps, mapServerEntryProps)(SearchPage);
