import { ListingCardFragment } from "../graphql/fragments/types/ListingCardFragment";
import { ListingData } from "./listings";

//from listing_document_builder.rb
const sizeFields = ["Tops & dresses", "Bottoms", "Shoes", "Accessories"];

export const extractListingSizeText = (listing: Partial<ListingCardFragment> | ListingData) => {
  const sizes = "size" in listing ? listing.size : null;
  if (sizes == null) return;

  let text = "Size: ";
  //sizes are in the format "<sizeField>| <size>" and we don't want "<sizeField>|" bit
  sizes.forEach((s) => {
    sizeFields.forEach((field) => {
      if (s.includes(field)) text += s.replace(`${field}|`, "") + ", ";
    });
  });
  return text.trim().slice(0, -1); //remove whitespace and comma at the end
};

//removes size strings from the given text (check samples below)
export const removeSizeText = (title: string): string =>
  title
    .replace(/(\-|,|\s|\(|\/|\))+size(\s|\-|\d\.?\d?|US|UK|EU|AU|Italian|\(|\/|\))+/gi, " ")
    .trim();
/*
Cotton Hugh Dress - size 8 -> 100% Cotton Hugh Dress
Silk gathered dress with strap detail, Size 8 -> Silk gathered dress with strap detail
Skinny Jeans in Mid Blue - Size 32 -> Skinny Jeans in Mid Blue
Time Is Now Skirt - Size 8 -> Time Is Now Skirt
Silk and copper size 10 dress. Very unusual! -> Silk and copper dress. Very unusual!
Ruby Nubuck Ballet Flats Blue - Size EU39 -> Ruby Nubuck Ballet Flats Blue
Oversized Unitees 3 Hoodie -> Oversized Unitees Hoodie
pants, Size Italian 42 -> pants
Japan S - Men's Size 7 -> Japan S
Odyssey React 2 Flyknit Running Shoes (Size US 10 / EU 42) -> Odyssey React 2 Flyknit Running Shoes
*/

export const removeBrandText = (title: string, brand: string): string =>
  title.toLowerCase().replace(brand.toLowerCase(), "").trim();
