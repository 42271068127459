import { createStyles, makeStyles, Typography } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import React, { ReactElement } from "react";

const useStyles = makeStyles(() =>
  createStyles({
    tooltip: {
      backgroundColor: "white",
      border: "1px solid black",
    },
  })
);

export interface Props {
  title: string | ReactElement;
  size?: string;
  icon?: ReactElement;
}

export const InfoTooltip = ({ title, size, icon }: Props) => {
  const classes = useStyles();

  return (
    <Tooltip
      title={
        <Typography variant="subtitle1" color="textPrimary">
          {title}
        </Typography>
      }
      arrow
      enterTouchDelay={0}
      leaveTouchDelay={2500}
      classes={{
        tooltip: classes.tooltip,
      }}
      style={{
        fontSize: size ? size : undefined,
      }}
    >
      {icon ? icon : <InfoIcon color="action" />}
    </Tooltip>
  );
};

export default InfoTooltip;
