import React from "react";
import { useQuery } from "@apollo/client";
import {
  GetCollectionListings,
  GetCollectionListingsVariables,
} from "../../../composites/CollectionListingsBlock/queries/types/GetCollectionListings";
import GET_PERSON_LISTINGS from "../../../composites/CollectionListingsBlock/queries/GetCollectionListings";
import ListingCard from "../../../composites/v2/ListingCard/ListingCard";
import Section from "../components/Section";
import Grid from "../components/Grid";
import { translate } from "../../../../utils/i18n";

type Props = {
  collectionId: number;
};

const section = (collectionUrl) => ({
  id: "shop_by_new",
  title: translate("web.discovery.sections.shop_new_arrivals.title"),
  subtitle: translate("web.discovery.sections.shop_new_arrivals.subtitle"),
  links: [
    {
      tid: "web.discovery.sections.shop_new_arrivals.links.all_new_arrivals",
      href: collectionUrl,
    },
  ],
});

export default function NewArrivalsSection({ collectionId }: Props) {
  const { data, previousData, error } = useQuery<
    GetCollectionListings,
    GetCollectionListingsVariables
  >(GET_PERSON_LISTINGS, {
    variables: {
      collectionId,
      perPage: 8,
      page: 1,
    },
  });

  if (error) {
    console.error(error);
    return null;
  }

  const collection = (data || previousData)?.collection;
  const usableData = collection?.listings;
  const listings = usableData?.results || [];

  if (!listings.length) return null;

  return (
    <Section {...section(collection.url)}>
      <Grid style={{ gap: "16px" }}>
        {listings.map((listing) => (
          <ListingCard key={`listing_${listing.id}`} listing={listing} hideFavouriteIcon />
        ))}
      </Grid>
    </Section>
  );
}
